import { config } from './config'

const formatDate = (date) => {
  let d = new Date(date)
  const month = d.toLocaleString('default', { month: 'short' })
  return `${month} ${d.getDate()}, ${d.getFullYear()}`
}

const formatName = (user) => {
  if (!user) {
    return null
  }
  return `${user.firstName || ''} ${user.lastName || ''}`.trim()
}

const getImageUrl = (url, defaultValue) => {
  if (!url) {
    return defaultValue
  }
  return `${config.CDN_BASE_URL}/${url}`
}


const getThumbnailForVideo = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[7].length === 11) {
    return `https://img.youtube.com/vi/${match[7]}/hqdefault.jpg`
  }
  return null
}
const getEmbedUrlForVideo = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[7].length === 11) {
    return `https://youtube.com/embed/${match[7]}`
  }
  return null
}
export {
  formatName,
  formatDate,
  getImageUrl,
  getThumbnailForVideo,
  getEmbedUrlForVideo
}
