import * as React from 'react'
import { replaceHighlightTag } from '../../data/dictionary'

const RenderHtml = ({ html, tag }) => {
  const highlighted = replaceHighlightTag(html);
  if (tag === 'div') {
    return <div style={{width: '100%'}} dangerouslySetInnerHTML={{ __html: highlighted }}></div>
  }
  return <span dangerouslySetInnerHTML={{ __html: highlighted }}></span>
}

export default RenderHtml
